import React, { createContext, useContext, useEffect, useReducer, useRef } from 'react';
import { MultiSelectGridOptionWithImage, MultiSelectOption } from 'src/types/Forms/Forms';
import { PropertiesContext } from './PropertiesContext';
import { KPIOptions, ReportBuilderKPIMap } from 'data/reportBuilderData';
import { Report } from 'src/types/User/user';
import { UserApi } from 'services/api/user';
import { useToast } from '@/components/ui/use-toast';
import { isEqual, sortBy } from 'lodash';
import { useUser } from './UserContext';
import moment from 'moment';
import _ from 'lodash';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

export interface ReportBuilderContextType {
  state: StateType;
  pdfRef: React.RefObject<HTMLDivElement>;
  dispatch: React.Dispatch<ActionType>;
  generateNewReport: () => void;
  regenerateReport: () => void;
  refreshReport: () => void;
  saveReport: (returnToPreview: boolean) => void;
  exportToPDF: () => void;
}

interface StateType {
  reportTitle?: string;
  propertyOptions: MultiSelectOption[];
  selectedProperties: MultiSelectOption[];
  generatedReportProperties: MultiSelectOption[];
  selectedDate: Date | undefined;
  generatedReportDate: Date | undefined;
  allKPIs: MultiSelectOption[];
  selectedKPIs: MultiSelectGridOptionWithImage[];
  generatedReportKPIs: MultiSelectGridOptionWithImage[];
  loadedReport?: string;
  isGeneratingReport: boolean;
  isViewingReport: boolean;
  isPreviewingReport: boolean;
  isEditingReport: boolean;
  isSavingOrUpdatingReport: boolean;
  isDeletingReport: boolean;
  hasUnsavedChanges: boolean;
  clearFlag: number;
  isSavingReport: boolean;
}

type ActionType =
  | { type: 'SET_REPORT_TITLE'; payload: string | undefined }
  | { type: 'SET_PROPERTY_OPTIONS'; payload: MultiSelectOption[] }
  | { type: 'SET_SELECTED_PROPERTIES'; payload: MultiSelectOption[] }
  | { type: 'SET_GENERATED_REPORT_PROPERTIES'; payload: MultiSelectOption[] }
  | { type: 'SET_SELECTED_DATE'; payload: Date | undefined }
  | { type: 'SET_GENERATED_REPORT_DATE'; payload: Date | undefined }
  | { type: 'SET_ALL_KPIS'; payload: MultiSelectOption[] }
  | { type: 'SET_SELECTED_KPIS'; payload: MultiSelectGridOptionWithImage[] }
  | { type: 'SET_GENERATED_REPORT_KPIS'; payload: MultiSelectGridOptionWithImage[] }
  | { type: 'SET_IS_GENERATING_REPORT'; payload: boolean }
  | { type: 'SET_IS_VIEWING_REPORT'; payload: boolean }
  | { type: 'SET_IS_PREVIEWING_REPORT'; payload: boolean }
  | { type: 'SET_IS_EDITING_REPORT'; payload: boolean }
  | { type: 'SET_IS_SAVING_OR_UPDATING_REPORT'; payload: boolean }
  | { type: 'SET_IS_DELETING_REPORT'; payload: boolean }
  | { type: 'LOAD_REPORT'; payload: Report }
  | { type: 'SET_LOADED_REPORT'; payload: string | undefined }
  | { type: 'SET_HAS_UNSAVED_CHANGES'; payload: boolean }
  | { type: 'SET_CLEAR_FLAG'; payload: number }
  | { type: 'SET_IS_SAVING_REPORT'; payload: boolean }
  | { type: 'RESET' };

const initialState: StateType = {
  reportTitle: '',
  propertyOptions: [],
  selectedProperties: [],
  generatedReportProperties: [],
  selectedDate: new Date(),
  generatedReportDate: undefined,
  allKPIs: KPIOptions.flatMap(option =>
    option.KPIs.map(kpi => ({
      value: kpi.value,
      label: kpi.label,
    }))
  ),
  selectedKPIs: [],
  generatedReportKPIs: [],
  loadedReport: undefined,
  isGeneratingReport: false,
  isViewingReport: false,
  isPreviewingReport: true,
  isEditingReport: false,
  isSavingOrUpdatingReport: false,
  isDeletingReport: false,
  hasUnsavedChanges: false,
  clearFlag: 0,
  isSavingReport: false,
};

const reducer = (state: StateType, action: ActionType): StateType => {
  switch (action.type) {
    case 'SET_REPORT_TITLE':
      return { ...state, reportTitle: action.payload };
    case 'SET_PROPERTY_OPTIONS':
      return { ...state, propertyOptions: action.payload };
    case 'SET_SELECTED_PROPERTIES':
      return { ...state, selectedProperties: action.payload };
    case 'SET_GENERATED_REPORT_PROPERTIES':
      return { ...state, generatedReportProperties: action.payload };
    case 'SET_SELECTED_DATE':
      return { ...state, selectedDate: action.payload };
    case 'SET_GENERATED_REPORT_DATE':
      return { ...state, generatedReportDate: action.payload };
    case 'SET_ALL_KPIS':
      return { ...state, allKPIs: action.payload };
    case 'SET_SELECTED_KPIS':
      return { ...state, selectedKPIs: action.payload };
    case 'SET_GENERATED_REPORT_KPIS':
      return { ...state, generatedReportKPIs: action.payload };
    case 'SET_IS_GENERATING_REPORT':
      return { ...state, isGeneratingReport: action.payload };
    case 'SET_IS_VIEWING_REPORT':
      return { ...state, isViewingReport: action.payload };
    case 'SET_IS_PREVIEWING_REPORT':
      return { ...state, isPreviewingReport: action.payload };
    case 'SET_IS_EDITING_REPORT':
      return { ...state, isEditingReport: action.payload };
    case 'SET_IS_SAVING_OR_UPDATING_REPORT':
      return { ...state, isSavingOrUpdatingReport: action.payload };
    case 'SET_IS_DELETING_REPORT':
      return { ...state, isDeletingReport: action.payload };
    case 'LOAD_REPORT':
      return {
        ...state,
        reportTitle: action.payload.reportTitle,
        selectedProperties: action.payload.properties,
        generatedReportProperties: action.payload.properties,
        selectedDate: new Date(action.payload.reportDate),
        generatedReportDate: new Date(action.payload.reportDate),
        selectedKPIs: action.payload.KPIs.filter(KPI => KPI.type !== 'text'),
        generatedReportKPIs: action.payload.KPIs,
        isGeneratingReport: false,
        isViewingReport: true,
        isPreviewingReport: true,
        isEditingReport: false,
        isSavingOrUpdatingReport: false,
      };
    case 'SET_LOADED_REPORT':
      return { ...state, loadedReport: action.payload };
    case 'SET_HAS_UNSAVED_CHANGES':
      return { ...state, hasUnsavedChanges: action.payload };
    case 'SET_CLEAR_FLAG':
      return { ...state, clearFlag: action.payload };
    case 'RESET':
      return {
        ...initialState,
        propertyOptions: state.propertyOptions,
        selectedProperties: state.propertyOptions,
      };
    default:
      return state;
  }
};

export const ReportBuilderContext = createContext<ReportBuilderContextType | null>(null);

export const ReportBuilderContextProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const propertiesContext = useContext(PropertiesContext);
  const { user: currentUser, refreshUser } = useUser();
  const { toast } = useToast();
  const pdfRef = useRef<HTMLDivElement>(null);

  // useEffect to map all building names to a MultiSelectOption
  // In the case of multiple entries with the same building name but different building IDs, the MultiSelectOption will have label: building.name and value: a concatenated csv string of all building ids
  useEffect(() => {
    if (currentUser?.email === 'dev.prod.soulrooms@gmail.com') {
      dispatch({
        type: 'SET_PROPERTY_OPTIONS',
        payload: [
          { label: 'BLVD West', value: 'building1' },
          { label: 'Colony Club', value: 'building2' },
          { label: 'Esker Square', value: 'building3' },
          { label: 'Retreat at Farmington', value: 'building4' },
          { label: 'Ridgeline at Canton', value: 'building5' },
          { label: 'The Grove', value: 'building6' },
          { label: 'The Landings on East Hill', value: 'building7' },
          { label: 'The Loop on Greenfield', value: 'building8' },
          { label: 'Veridian Castleton', value: 'building9' },
        ],
      });
      return;
    }
    const uniqueBuildingsMap = new Map();

    propertiesContext?.state?.data?.forEach(p => {
      if (p.building) {
        const existingEntry = uniqueBuildingsMap.get(p.buildingName);

        if (existingEntry) {
          // If an entry with the same building name already exists, add the new building id to the set
          uniqueBuildingsMap.set(p.buildingName, {
            label: p.buildingName || p.building.shortAddress,
            value: p.buildingName || p.building.shortAdress,
          });
        } else {
          // If no entry with the same building name exists, create a new one with a single ID
          uniqueBuildingsMap.set(p.buildingName, {
            label: p.buildingName || p.building.shortAddress,
            value: p.buildingName || p.building.shortAddress,
          });
        }
      }
    });

    dispatch({
      type: 'SET_PROPERTY_OPTIONS',
      payload: Array.from(uniqueBuildingsMap.values()).sort((a, b) => a.label.localeCompare(b.label)),
    });
  }, [propertiesContext?.state?.data]);

  /**
   * Helper function to calculate the intiial grid layout of the generatedReportKPIs after data has been fetched (or re-fecthed) from tableau
   * If passed pre-existing items with set layout, will just maintain that. If passed fresh items that have not been slotted into the layout yet, it will calculate their placement
   *
   */
  const calculateInitialLayout = (kpis: MultiSelectGridOptionWithImage[]) =>
    kpis.map((kpi, index) => {
      if (kpi.x && kpi.y && kpi.w && kpi.h) return kpi;
      const isBig =
        kpi.id === ReportBuilderKPIMap.AGING_SUMMARY ||
        kpi.id === ReportBuilderKPIMap.TENANTS_WITH_A_BALANCE ||
        kpi.id === ReportBuilderKPIMap.CURRENT_TENANT_UNIT_STATUS ||
        kpi.id === ReportBuilderKPIMap.OCCUPANCY_BY_PROPERTY ||
        kpi.id === ReportBuilderKPIMap.TOP_10_TRADE_OUT_GAINS ||
        kpi.id === ReportBuilderKPIMap.BOTTOM_10_TRADE_OUT_GAINS ||
        kpi.id === ReportBuilderKPIMap.T6_LEASE_RENEWAL_RATES_BY_PROPERTY;
      const isWide = kpi.id === ReportBuilderKPIMap.CURRENT_OCCUPANCY_TOTAL;
      return {
        ...kpi,
        draggable: true,
        x: kpi.x ? kpi.x : (index % 2) * 1, // Alternates between 0 and 1 for two columns
        y: kpi.y ? kpi.y : Math.floor(index / 2), // Increments every two items to keep two items per row
        w: kpi.w ? kpi.w : isWide || isBig ? 2 : 1, // Set column width
        h: kpi.h ? kpi.h : isBig ? 3 : isWide ? 1 : 2, // Set row height
        minW: 1,
        maxW: 2,
        minH: 1,
        maxH: 4,
      };
    });

  const getGenerateReportErrorText = (errorType: 'kpi' | 'property' | 'title') => {
    switch (errorType) {
      case 'kpi':
        return 'At least one KPI must be selected to generate a report';
      case 'property':
        return 'At least one property must be selected to generate a report';
      case 'title':
        return 'A report cannot be generated without a title';
    }
  };

  const getSaveReportErrorText = (errorType: 'generating' | 'viewing' | 'default' | 'noTitle') => {
    switch (errorType) {
      case 'generating':
        return 'Cannot save a KPI while generating a report';
      case 'viewing':
        return 'Please generate a report before attempting to save';
      case 'default':
        return 'Error saving report. Please check report for errors';
      case 'noTitle':
        return 'A report cannot be saved without a title';
    }
  };

  /**
   * Helper function to generate a new report from scratch when a user is viewing an empty report page
   * Fetches data from Tableau API for all selectedKPIs, then calculate the initial grid layout of the resulting report
   */
  const generateNewReport = async () => {
    if (state.selectedKPIs.length === 0 || state.selectedProperties.length === 0 || !state.reportTitle) {
      const errorText = getGenerateReportErrorText(
        state.selectedKPIs.length === 0 ? 'kpi' : state.selectedProperties.length === 0 ? 'property' : 'title'
      );
      toast({
        description: errorText,
        variant: 'error',
        duration: 3000,
      });
      return;
    }

    dispatch({
      type: 'SET_IS_GENERATING_REPORT',
      payload: true,
    });

    try {
      const reqData = state.selectedKPIs.map(item => {
        return {
          id: item.id,
          chartType: item.chartType,
        };
      });
      const propertyNames = _.isEqual(state.selectedProperties, state.propertyOptions)
        ? undefined
        : state.selectedProperties.map(property => property.label).join(',');
      const res = await UserApi.getTableauViewData(reqData, propertyNames);
      console.log('response from getTableauViewData: ', res.data);
      // appending the tableau data into a new object that combines the selectedKPIs state with the data found
      const KPIsWithData = state.selectedKPIs.map(kpi => {
        const data = res.data.find((item: any) => item.id === kpi.id);
        return {
          ...kpi,
          data: data.data,
        };
      });
      dispatch({
        type: 'SET_GENERATED_REPORT_DATE',
        payload: state.selectedDate,
      });
      dispatch({
        type: 'SET_GENERATED_REPORT_PROPERTIES',
        payload: state.selectedProperties,
      });
      dispatch({
        type: 'SET_GENERATED_REPORT_KPIS',
        payload: calculateInitialLayout(KPIsWithData),
      });
      dispatch({
        type: 'SET_IS_VIEWING_REPORT',
        payload: true,
      });
    } catch (error) {
      console.log(error);
    } finally {
      dispatch({
        type: 'SET_IS_GENERATING_REPORT',
        payload: false,
      });
    }
  };

  /**
   * Helper function to regenerate a report according to today's Date
   * Maintains the grid layout of pre-existing generatedReportKPIs, as well as any text fields that the user added
   * If the user has selected additional KPIs from the KPI menu (or changed the selected properties), it will fetch that data as well and append them to the end of the grid layout
   */
  const regenerateReport = async () => {
    if (state.selectedKPIs.length === 0 || state.selectedProperties.length === 0 || !state.reportTitle) {
      const errorText = getGenerateReportErrorText(
        state.selectedKPIs.length === 0 ? 'kpi' : state.selectedProperties.length === 0 ? 'property' : 'title'
      );
      toast({
        description: errorText,
        variant: 'error',
        duration: 3000,
      });
      return;
    }
    // 1) Remove any reports that are no longer in the selectedKPIs list
    const filteredKPIs = state.generatedReportKPIs.filter(generatedKPI => {
      if (generatedKPI.type === 'text') return true;
      return state.selectedKPIs.some(selectedKPI => selectedKPI.id === generatedKPI.id);
    });
    const newlySelectedKPIs = state.selectedKPIs.filter(
      selectedKPI => !filteredKPIs.some(filteredKPI => filteredKPI.id === selectedKPI.id)
    );
    const allKPIs = [...filteredKPIs, ...newlySelectedKPIs];

    dispatch({
      type: 'SET_IS_GENERATING_REPORT',
      payload: true,
    });

    try {
      const reqData = state.selectedKPIs
        .filter(kpi => kpi.type !== 'text')
        .map(kpi => {
          return {
            id: kpi.id,
            chartType: kpi.chartType,
          };
        });
      const propertyNames = _.isEqual(state.selectedProperties, state.propertyOptions)
        ? undefined
        : state.selectedProperties.map(property => property.label).join(',');
      const res = await UserApi.getTableauViewData(reqData, propertyNames);
      console.log(res.data);
      // if its a text field, return the normal KPI
      // otherwise update the KPIs data
      const KPIsWithData = allKPIs.map(kpi => {
        if (kpi.type === 'text') return kpi;
        const data = res.data.find((item: any) => item.id === kpi.id);
        return {
          ...kpi,
          data: data.data,
        };
      });
      dispatch({
        type: 'SET_SELECTED_DATE',
        payload: new Date(),
      });
      dispatch({
        type: 'SET_GENERATED_REPORT_DATE',
        payload: state.selectedDate,
      });
      dispatch({
        type: 'SET_GENERATED_REPORT_PROPERTIES',
        payload: state.selectedProperties,
      });
      const newLayout = calculateInitialLayout(KPIsWithData);
      dispatch({
        type: 'SET_GENERATED_REPORT_KPIS',
        payload: newLayout,
      });
      dispatch({
        type: 'SET_IS_VIEWING_REPORT',
        payload: true,
      });
    } catch (error) {
      console.log(error);
    } finally {
      dispatch({
        type: 'SET_IS_GENERATING_REPORT',
        payload: false,
      });
    }
  };

  /**
   * Helper function to refresh a LOADED report according to today's Date
   * Maintains the grid layout of pre-existing generatedReportKPIs, as well as any text fields that the user added
   * If the user has selected additional KPIs from the KPI menu (or changed the selected properties), they will be reset to match the original loaded report
   */
  const refreshReport = async () => {
    // check if there is a mismatch between generated properties and selected properties
    // mismatch === difference in length, OR some item.value (the property id) exists in one but does not exist in the other
    // reset selected properties if user has changed them in the selected properties multi-select then refreshed their report
    // without re-generating it
    if (
      state.selectedProperties.length !== state.generatedReportProperties.length ||
      !isEqual(
        sortBy(state.selectedProperties.map(item => item.value)),
        sortBy(state.generatedReportProperties.map(item => item.value))
      )
    ) {
      dispatch({
        type: 'SET_SELECTED_PROPERTIES',
        payload: state.generatedReportProperties.map(item => {
          return {
            label: item.label,
            value: item.value,
          };
        }),
      });
    }

    // check if there is a mismatch between generated and select reportKPIs
    // If that's the case, reset the selected KPIs to match the generated report KPIs by filtering for non-text entries
    if (
      !isEqual(
        sortBy(state.selectedKPIs.map(item => item.value)),
        sortBy(state.generatedReportKPIs.filter(item => item.type === 'kpi').map(item => item.value))
      )
    ) {
      const resetSelectedKPIs: MultiSelectGridOptionWithImage[] = state.generatedReportKPIs
        .filter(item => item.type === 'kpi')
        .map(item => {
          return {
            id: item.id,
            label: item.label,
            value: item.value,
            chartType: item.chartType,
            type: item.type,
            draggable: true,
            imageUrl: item.imageUrl,
          };
        });
      dispatch({
        type: 'SET_SELECTED_KPIS',
        payload: resetSelectedKPIs,
      });
    }

    dispatch({
      type: 'SET_IS_GENERATING_REPORT',
      payload: true,
    });

    try {
      const reqData = state.generatedReportKPIs.map(item => {
        return {
          id: item.id,
          chartType: item.chartType,
        };
      });
      const propertyNames = _.isEqual(state.selectedProperties, state.propertyOptions)
        ? undefined
        : state.selectedProperties.map(property => property.label).join(',');

      const res = await UserApi.getTableauViewData(reqData, propertyNames);
      const newGeneratedReportKPIs = state.generatedReportKPIs.map(kpi => {
        if (kpi.type === 'text') return kpi;

        const data = res.data.find((item: any) => item.id === kpi.id);
        return {
          ...kpi,
          data: data.data,
        };
      });

      dispatch({
        type: 'SET_GENERATED_REPORT_KPIS',
        payload: newGeneratedReportKPIs,
      });
      dispatch({
        type: 'SET_SELECTED_DATE',
        payload: new Date(),
      });
      dispatch({
        type: 'SET_GENERATED_REPORT_DATE',
        payload: new Date(),
      });
    } catch (error) {
      console.log(error);
    } finally {
      dispatch({
        type: 'SET_IS_GENERATING_REPORT',
        payload: false,
      });
    }
  };

  /**
   * Helper function to save a report to the database
   * Will not save if
   * 1) report is being generated
   * 2) no loaded and user tries to save with a pre-existing report name
   * Otherwise will save a new report OR update a pre-existing report if a pre-existing report is currently loaded
   */
  const saveReport = async (returnToPreview: boolean) => {
    if (state.isGeneratingReport || (!state.isGeneratingReport && !state.isViewingReport) || !state.reportTitle) {
      const errorText = getSaveReportErrorText(
        // error if saving when generating report
        state.isGeneratingReport
          ? 'generating'
          : // error if saving when no report generated
            !state.isGeneratingReport && !state.isViewingReport
            ? 'viewing'
            : // error if report has no title
              !state.reportTitle
              ? 'noTitle'
              : // default error
                'default'
      );
      toast({
        description: errorText,
        variant: 'error',
        duration: 3000,
      });
      return;
    }

    const saveReportPayload = {
      reportTitle: state.reportTitle,
      reportDate: state.generatedReportDate,
      properties: state.generatedReportProperties,
      KPIs: state.generatedReportKPIs,
    };

    try {
      dispatch({
        type: 'SET_IS_SAVING_OR_UPDATING_REPORT',
        payload: true,
      });

      const updatedUserForm = {
        ...currentUser,
        reports: [saveReportPayload],
      };
      await UserApi.updateCurrentUser(updatedUserForm);
      const updatedUser = await refreshUser();
      // get the newly saved report so we can set the loaded report's id to that
      const newReport = updatedUser?.reports?.find(report => report.reportTitle === state.reportTitle);
      dispatch({
        type: 'SET_HAS_UNSAVED_CHANGES',
        payload: false,
      });
      dispatch({
        type: 'SET_LOADED_REPORT',
        payload: newReport?.id,
      });
      toast({
        variant: 'success',
        description: 'Report saved successfully',
        duration: 2000,
      });
    } catch (error) {
      const errorText = getSaveReportErrorText(
        // error if saving while generating report
        state.isGeneratingReport
          ? 'generating'
          : // error if saving on empty report page
            !state.isGeneratingReport && !state.isViewingReport
            ? 'viewing'
            : // error when saving without a report title
              !state.reportTitle
              ? 'noTitle'
              : // default error
                'default'
      );
      toast({
        description: errorText,
        variant: 'error',
        duration: 3000,
      });
    } finally {
      dispatch({
        type: 'SET_IS_SAVING_OR_UPDATING_REPORT',
        payload: false,
      });
      if (returnToPreview) {
        if (state.isEditingReport) {
          dispatch({
            type: 'SET_IS_EDITING_REPORT',
            payload: false,
          });
          dispatch({
            type: 'SET_IS_PREVIEWING_REPORT',
            payload: true,
          });
        }
      }
    }
  };

  const exportToPDF = async () => {
    if (!pdfRef.current) return;

    try {
      // Temporarily modify styles to ensure all content is visible
      const originalStyle = pdfRef.current.style.cssText;
      pdfRef.current.style.overflow = 'visible';
      pdfRef.current.style.height = 'auto';

      // Use html2canvas to capture the entire content of the container
      const canvas = await html2canvas(pdfRef.current, {
        scale: 2, // High resolution for sharp rendering
        useCORS: true, // Handle cross-origin issues for images
      });

      // Restore the original styles
      pdfRef.current.style.cssText = originalStyle;

      // Create the PDF
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF('p', 'mm', 'a4'); // Portrait orientation, mm units, A4 size

      const imgWidth = 190; // Width of A4 in mm
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      const pageHeight = 297; // Height of A4 in mm
      let heightLeft = imgHeight;
      let position = 0;

      // Add the first page
      pdf.addImage(imgData, 'PNG', 10, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;

      // Add additional pages if needed
      while (heightLeft > 0) {
        position = heightLeft - imgHeight; // Adjust the position for the next page
        pdf.addPage();
        pdf.addImage(imgData, 'PNG', 10, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }

      // Save the PDF
      pdf.save('report.pdf');
    } catch (error) {
      console.error('Error generating PDF:', error);
    }
  };

  // unsaved changes can be the following
  // 1) user has a loaded report ID and has changed the report via
  // a) regenerating the report
  // b) refreshing the report
  // c) changing the report title
  // 2) User has created an new report and the user does NOT have a matching report title in their .reports array
  useEffect(() => {
    // no unsaved changes if no user
    if (!currentUser)
      dispatch({
        type: 'SET_HAS_UNSAVED_CHANGES',
        payload: false,
      });
    // has unsaved changes if no loaded report and viewing a generated report
    if (!state.loadedReport && state.isViewingReport)
      dispatch({
        type: 'SET_HAS_UNSAVED_CHANGES',
        payload: true,
      });

    if (state.loadedReport) {
      const existingReport = currentUser?.reports?.find(report => report.id === state.loadedReport);
      // has unsaved changes if a loaded report is found and it differs from the generated values
      if (
        !isEqual(existingReport?.KPIs, state.generatedReportKPIs) ||
        !isEqual(existingReport?.properties, state.generatedReportProperties) ||
        !moment(existingReport?.reportDate).isSame(moment(state.generatedReportDate), 'day') ||
        existingReport?.reportTitle !== state.reportTitle
      ) {
        dispatch({
          type: 'SET_HAS_UNSAVED_CHANGES',
          payload: true,
        });
      } else {
        dispatch({
          type: 'SET_HAS_UNSAVED_CHANGES',
          payload: false,
        });
      }
    }
  }, [
    currentUser?.id,
    state.generatedReportKPIs,
    state.generatedReportProperties,
    state.generatedReportDate,
    state.reportTitle,
    state.loadedReport,
    state.isViewingReport,
  ]);

  return (
    <ReportBuilderContext.Provider
      value={{ state, dispatch, generateNewReport, regenerateReport, refreshReport, saveReport, pdfRef, exportToPDF }}>
      {children}
    </ReportBuilderContext.Provider>
  );
};
