import React, { createContext, useReducer } from 'react';

export interface LeniQContextType {
  state: StateType;
  dispatch: React.Dispatch<ActionType>;
}

interface StateType {
  leniQDrawerOpen: boolean;
  leniQDrawerFullscreen: boolean;
  question: string | undefined;
}

type ActionType =
  | { type: 'SET_LENIQ_DRAWER_OPEN'; payload: boolean }
  | { type: 'TOGGLE_LENIQ_DRAWER_FULLSCREEN'; payload: boolean }
  | { type: 'SET_LENIQ_DRAWER_FULLSCREEN'; payload: boolean }
  | { type: 'SET_QUESTION'; payload: string | undefined };

const initialState: StateType = {
  leniQDrawerOpen: false,
  leniQDrawerFullscreen: false,
  question: undefined,
};

const reducer = (state: StateType, action: ActionType): StateType => {
  switch (action.type) {
    case 'SET_LENIQ_DRAWER_OPEN':
      return { ...state, leniQDrawerOpen: action.payload };
    case 'TOGGLE_LENIQ_DRAWER_FULLSCREEN':
      return { ...state, leniQDrawerFullscreen: !state.leniQDrawerFullscreen };
    case 'SET_LENIQ_DRAWER_FULLSCREEN':
      return { ...state, leniQDrawerFullscreen: action.payload };
    case 'SET_QUESTION':
      return { ...state, question: action.payload };
    default:
      return state;
  }
};

export const LeniQContext = createContext<LeniQContextType | null>(null);

export const LeniQContextProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return <LeniQContext.Provider value={{ state, dispatch }}>{children}</LeniQContext.Provider>;
};
